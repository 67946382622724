/*
 * @Author: 月魂
 * @Date: 2022-09-19 10:18:40
 * @LastEditTime: 2022-11-07 15:05:30
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\lib\rem.js
 */
// rem.js
// 基准大小
const baseSize = 64;
// 设置 rem 函数
function setRem () {
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  const width = document.documentElement.clientWidth || document.body.clientWidth;
  const scale = width >= 800 ? 500 / 750 : width / 750;
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = (baseSize * scale) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}
