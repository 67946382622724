/*
 * @Author: 月魂
 * @Date: 2022-09-19 10:18:40
 * @LastEditTime: 2022-09-26 10:41:28
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\i18n\langs\index.js
 */
import en from './en'
import zh from './zh'
import fr from './fr'
export default {
  en,
  zh,
  fr
}
