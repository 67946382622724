/*
 * @Author: 月魂
 * @Date: 2022-09-19 10:18:40
 * @LastEditTime: 2022-11-14 11:45:33
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import i18n from '../i18n/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/taste',
    name: 'Taste',
    component: () => import('../views/Taste.vue')
  },
  {
    path: '/taste/video',
    name: 'TasteVideo',
    component: () => import('../views/Taste/Video.vue')
  },
  {
    path: '/taste/food/:id',
    name: 'TasteFood',
    component: () => import('../views/Taste/Food.vue')
  },
  {
    path: '/ingredients',
    name: 'Ingredients',
    component: () => import('../views/Ingredients.vue')
  },
  {
    path: '/ingredients/material/:id/:sId',
    name: 'IngredientsMaterial',
    component: () => import('../views/Ingredients/Material.vue')
  },
  {
    path: '/stunt',
    name: 'Stunts',
    component: () => import('../views/Stunts.vue')
  },
  {
    path: '/utensils',
    name: 'Utensils',
    component: () => import('../views/Utensils.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/Shop.vue')
  },
  {
    path: '/recipe',
    name: 'Recipe',
    component: () => import('../views/Recipe.vue')
  },
  {
    path: '/teaching',
    name: 'Teaching',
    component: () => import('../views/Teaching.vue')
  },
  {
    path: '/intro',
    name: 'Intro',
    component: () => import('../views/Intro.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 })
        }
      }, 100)
    })
  }
})

router.beforeEach(async (to, from, next) => {
  if (i18n.te('taste')) {
    next()
  } else {
    const zhReq = [
      '/json/setting-zh.json',
      '/json/ingredients-zh.json',
      '/json/recipe-zh.json',
      '/json/shop-zh.json',
      '/json/stunt-zh.json',
      '/json/taste-zh.json',
      '/json/teaching-zh.json',
      '/json/utensils-zh.json'
    ];
    const frReq = [
      '/json/setting-fr.json',
      '/json/ingredients-fr.json',
      '/json/recipe-fr.json',
      '/json/shop-fr.json',
      '/json/stunt-fr.json',
      '/json/taste-fr.json',
      '/json/teaching-fr.json',
      '/json/utensils-fr.json'
    ]
    await axios.all(zhReq.map(url => axios.get(url, { headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }}))).then(resArr => {
      resArr.map(res => i18n.mergeLocaleMessage('zh', JSON.parse(JSON.stringify(res.data))))
    })
    await axios.all(frReq.map(url => axios.get(url, { headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }}))).then(resArr => {
      resArr.map(res => i18n.mergeLocaleMessage('fr', res.data))
    })
    next()
  }
})

export default router
