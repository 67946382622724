<!--
 * @Author: 月魂
 * @Date: 2021-11-15 14:29:32
 * @LastEditTime: 2022-11-14 15:52:31
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\App.vue
-->
<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default{
  name: 'App',
  data() {
    return {
      transitionName: ''
    }
  },
  watch:{
    '$route' (to, from) {
      if (to.path === '/home' && from.path === '/') {
        this.transitionName = 'fold-left'
      } else {
        this.transitionName = ''
      }
    }
  }
}
</script>


<style lang="less">
@font-face {
  font-family: hui;
  src: url('https://image.wearetogether.com.cn/hui.otf');
}
@font-face {
  font-family: hua;
  src: url('https://image.wearetogether.com.cn/hua.ttf');
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // letter-spacing: 1px;
  line-height: 1.8;
  word-break: break-all; // 英文单词强制不换行
}
html,body {
  max-width: 100%;
  overflow-x: hidden;
}

.fold-left-enter-active {
  animation-name: fold-left-in;
  animation-duration: 1s;
}
.fold-left-leave-active {
  animation-name: fold-left-out;
  animation-duration: .3s;
}
@keyframes fold-left-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fold-left-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: .3;
  }
}

#app {
  // font-family: newFont, PingFang SC, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  text-align: justify;
}
.main-title {
  font-family: hui;
  text-align: left;
  padding-left: 10px;
  color: #fafde1;
}
.fir .item .text {
  font-family: hua;
}
.img {
  display: block;
  width: 100%;
}
// 时间线节点
.el-timeline-item__timestamp {
  color: #fff;
}

// 轮播图小圆点样式
.swiper-pagination-bullet, .swiper-pagination-bullet-active {
  width: 6px !important;
  height: 6px !important;
}

@media (min-width: 1000PX) {
  .swiper-pagination-bullet, .swiper-pagination-bullet-active {
    width: 16PX !important;
    height: 16PX !important;
  }
}

// 导航栏样式调整
// .van-nav-bar__content {
//   background-image: linear-gradient(to right, #ed6e5a, #b27c9f);
// }
// .van-nav-bar__title {
//   color: #fff !important;
// }
.van-nav-bar .van-icon {
  color: #000 !important;
}
.van-nav-bar__text {
  color: #000 !important;
}

// 图片预览组件样式调整
.van-image-preview__cover {
  width: 100%;
  top: 50px !important;
  color: #fff;
}

// tab调整
.ing-cont .van-tabs__nav--line {
  background: rgba(0, 0, 0, 0);
  padding: 0 16px;
  justify-content: space-around;
  .van-tab {
    color: #999;
    padding: 0 8px;
    border-radius: 20px;
    flex: initial;
    font-size: 12px;
    min-width: 80px;
  }
  .van-tab__text--ellipsis {
    -webkit-line-clamp: 2;
    line-height: 20px;
  }
  .van-tab--active {
    color: #999;
    background: #111138;
  }
  .van-tabs__line {
    height: 0;
  }
}

// home页遮罩层样式修改
.home-wrapper .van-overlay {
  background: url('./assets/wrapper.png') no-repeat;
  background-size: cover;
}

</style>
